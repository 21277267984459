<template>
    <div class="contents">
        <div class="return" @click="returnTap">{{$t('N_I_241')}}</div>
        <div class="swipers">
            <div class="swiper-container">
                <div class="swiper-wrapper">
                    <div class="swiper-slide" v-for="item in bannerLists.fileList ">
                        <img :src="item"/>
                    </div>
                </div>
                <div class="swiper-pagination"></div>
                <div class="swiper-button-prev">
<!--                    <img src="@/assets/images/markerSpace/left.png"/>-->
                </div>
                <div class="swiper-button-next">
<!--                    <img src="@/assets/images/markerSpace/right.png"/>-->
                </div>
                <div class="user-infos">
                  <div class="name">{{bannerLists.storeName}}</div>
                  <div class="user-cen tops">
                    <div class="icon">
                        <img src="@/assets/images/markerSpace/phone1.png"/>
                    </div>
                    <div class="name-s">
                        联系方式：{{bannerLists.storePhone}}
                    </div>
                  </div>
                    <div class="user-cen">
                        <div class="icon">
                            <img src="@/assets/images/markerSpace/address.png"/>
                        </div>
                        <div class="name-s">
                            地址：{{bannerLists.storeProvinceVal}}{{bannerLists.storeCityVal}}{{bannerLists.storeCountyVal}}{{bannerLists.storeAddress}}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import * as sid from "@/api/sidebaruserinfo.js";
import sidebarUserInfo from "@/components/sidebarUserInfo";
import topBanner from "@/components/topBanner";
import markerSpace from "@/components/markerSpace";
import * as api from "@/api/register";
import { getBankCardChoiceList } from "@/api/wallet";
import { getToken } from "@/util/auth";
import Swiper from "swiper";
import "swiper/dist/css/swiper.css";
export default {
    data() {
        return {
            bannerLists:[],
        };
    },
    components: { },
    watch: {},
    mounted() {
        setTimeout(()=>{
            new Swiper(".swiper-container", {
                pagination: ".swiper-pagination",
                loop: false, // 循环
                spaceBetween: 20, // swiper-slide 间的距离为0
                autoplay: 0, // 自动切换时间间隔
                speed: 1000, // 滑动速度
                prevButton: ".swiper-button-prev",
                nextButton: ".swiper-button-next",
            });
        },100)
        this.$nextTick(()=>{
            this.bannerLists = JSON.parse(localStorage.getItem('marketSpace'))
        })

    },
    created() {

    },
    methods: {
        returnTap(){
            this.$router.go(-1)
        },
    },
    destroyed() {
        localStorage.removeItem("marketSpace")
    },
};
</script>
<style lang="scss" scoped>
.tops{
  margin-top: 40px;
}
.icon{
  img{
    width: 28px;
    height: 28px;
  }
}
.name-s{
  font-size: 28px;
  margin-left: 5px;
}
.user-cen{
  display: flex;
  line-height: 30px;
  margin-bottom: 10px;
}
.name{
  color: #fff;
  font-size: 40px;
  font-weight: 600;
}
.user-infos{
  position: absolute;
  left: 80px;
  bottom: 240px;
  z-index: 111;
  color: #fff;
}
.swiper-button-pre{
}
.swiper-button-prev{
  top: 400px;
  left: 70px;
  background:url("../../assets/images/markerSpace/left.png")no-repeat !important;
  width: 66px;
  height: 66px;
}
.swiper-button-next{
  top: 400px;
  right: 70px;
  background:url("../../assets/images/markerSpace/right.png")no-repeat !important;
  width: 66px;
  height: 66px;
}
.swiper-container {
  width: 100%;
  height: 948px;
  position: relative;
  //margin: 20px auto;
}
.swiper-slide {
  width: 100% !important;
  height: 948px !important;
  padding-bottom: 20px;
  //height: 303px;
  background: #ffffff;
  border-radius: 10px 10px 10px 10px;
    img{
        width: 100%;
        height: 948px;
    }
}
.return{
  cursor: pointer;
  width: 98px;
  height: 48px;
  background: #B8B8B8;
  border-radius: 6px 6px 6px 6px;
  opacity: 1;
  color: #fff;
  font-size: 16px;
  margin-left: 60px;
  text-align: center;
  line-height: 48px;
  margin-top: 27px;
  position: absolute;
  z-index: 11;
}
</style>
